.service {
    background-color: var(--primary);

    &.service__style--2 {
        padding: 30px 35px;
        -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
        position: relative;
        z-index: 2;
        border-radius: 10px;

        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            padding: 30px 28px;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
            padding: 30px 25px;
        }

        @media only screen and (max-width: 767px) {
            padding: 30px 14px;
        }

        @media only screen and (max-width: 575px) {
            padding: 30px 18px;
            margin-top: 30px;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, #f61b10, #ef0963);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
            transition: all .3s cubic-bezier(.645, .045, .355, 1);
            border-radius: 10px;

            @media only screen and (max-width: 575px) {
                opacity: 1;
                visibility: visible;
            }
        }

        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: #f9004d;
            -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
            transition: all .3s cubic-bezier(.645, .045, .355, 1);

            @media only screen and (min-width: 768px) and (max-width: 991px) {
                margin-bottom: 11px;
            }

            @media only screen and (max-width: 575px) {
                color: #ffffff;
            }

            svg {
                stroke-width: 1 !important;
            }
        }

        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
                    transition: all .3s cubic-bezier(.645, .045, .355, 1);

                    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
                        font-size: 19px;
                    }

                    @media only screen and (max-width: 767px) {
                        font-size: 20px;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 991px) {
                        margin-bottom: 8px;
                    }

                    @media only screen and (max-width: 575px) {
                        color: #ffffff;
                    }

                    a {
                        color: inherit;

                        @media only screen and (max-width: 575px) {
                            color: #ffffff;
                        }
                    }
                }
            }

            p {
                -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
                transition: all .3s cubic-bezier(.645, .045, .355, 1);
                color: rgba(29, 29, 36, 0.75);

                @media only screen and (max-width: 575px) {
                    color: #ffffff;
                }

            }
        }

        &:hover {
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);

            &::before {
                opacity: 1;
                visibility: visible;
            }

            .icon {
                color: #ffffff;
            }

            .content {
                h3 {
                    &.title {
                        color: #ffffff;
                    }
                }

                p {
                    color: #ffffff;
                }
            }
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;

            .icon {
                padding-right: 20px;
            }
        }
    }
}

.justifypara {
    text-align: justify;
    text-justify: inter-word;
}
/* Appointment Page */
.appointment-page {
	position: relative;
	z-index: 1;
	// height: calc(100vh - 68px);
	min-height: 750px;
	width: 100%;
	background-size: cover;
	background-position: bottom left;
	// padding-top: 130px;
	padding-top: 30px;
	display: flex;
	align-items: center;

	&:after {
		content: attr(data-text);
		position: absolute;
		font-weight: 700;
		font-size: 245px;
		line-height: 1.3;
		font-family: var(--font-family-title);
		z-index: -1;
		left: 20px;
		color: #fff;
		filter: drop-shadow(0px 30px 50px rgba(255, 129, 57, 0.15));
		opacity: 0.4;
		bottom: 100px;

		@include respond('laptop') {
			line-height: 1;
			bottom: 30px;
		}
	}

	.sub-title {
		font-size: 24px;
	}

	.form-check {
		margin-bottom: 50px;
		line-height: 1;
		position: relative;
		padding-left: 45px;

		label {
			font-size: 18px;
			line-height: 1.6;
			font-weight: 500;
			color: #000;
		}

		.form-check-input {
			border-radius: 0;
			height: 30px;
			width: 30px;
			margin: 0;
			margin-right: 15px;
			border: 3px solid var(--primary);
			position: absolute;
			left: 0;

			@include respond('laptop') {
				border: 2px solid var(--primary);
			}

			@include respond('phone-land') {
				border: 1px solid var(--primary);
			}
		}

		@include respond('laptop') {
			margin-bottom: 50px;
		}

		@include respond('tab-port') {
			padding-left: 35px;

			label {
				font-size: 16px;
			}

			.form-check-input {
				height: 20px;
				width: 20px;
				margin-top: 1px;
			}
		}

		@include respond('phone') {
			margin-bottom: 30px;

			label {
				font-size: 14px;
			}

			.form-check-input {
				margin-right: 10px;
			}
		}
	}

	.appointment-form {
		.appointment-text {
			font-size: 28px;
			font-weight: 500;
			line-height: 2.8;
			margin-bottom: 30px;
			color: #000;

			@include respond('laptop') {
				font-size: 24px;
			}

			@include custommq ($max: 1199px) {
				font-size: 20px;
			}

			@include respond('tab-port') {
				font-size: 18px;
			}

			@include respond('phone-land') {
				font-size: 16px;
			}

			@include respond('phone') {
				line-height: 2.5;
				margin-bottom: 15px;
			}
		}

		.dropdown-toggle {
			@include respond('phone-land') {
				height: 35px;
			}

			@include respond('phone') {
				height: 32px;
				border-bottom: 1px solid #000 !important;
			}
		}

		.filter-option {
			font-size: 24px;

			@include respond('laptop') {
				font-size: 20px;
			}

			@include respond('tab-land') {
				font-size: 18px;
			}

			@include respond('tab-port') {
				font-size: 16px;
			}

			@include respond('phone') {
				font-size: 15px;
			}
		}

		.form-control {
			border: none;
			line-height: 1;
			width: 300px;
			text-align: center !important;
			color: var(--primary);
			background: transparent !important;
			border-bottom: 2px solid #000;
			transform: translateY(-5px);
			margin: 0 10px;
			border-radius: 0;
			font-size: 24px;
			display: inline-block;

			&::placeholder {
				opacity: 0.6;
			}

			&:last-child {
				width: 480px;
			}

			@include respond('laptop') {
				width: 270px;
				font-size: 20px;

				&:last-child {
					width: 400px;
				}
			}

			@include respond('tab-land') {
				font-size: 18px;
			}

			@include respond('tab-port') {
				width: 160px;
				height: 40px;
				padding: 5px 10px;
				font-size: 16px;

				&:last-child {
					width: 220px;
				}
			}

			@include respond('phone-land') {
				width: 120px;
				height: 35px;
				font-size: 15px;

				&:last-child {
					width: 150px;
				}
			}

			@include respond('phone') {
				margin: 0 5px;
				border-bottom: 1px solid #000;
				height: 32px;
			}
		}

		.dropdown {
			width: 320px !important;
			text-align: center !important;

			@include respond('laptop') {
				width: 250px !important;
			}

			@include respond('tab-port') {
				width: 160px !important;
			}
		}
	}

	@include respond('laptop') {
		min-height: 650px;
	}

	@include respond('tab-port') {
		padding-top: 70px;
		min-height: 600px;

		.sub-title {
			font-size: 20px;
		}

		&:after {
			font-size: 180px;
			line-height: 1;
		}
	}

	@include respond('phone-land') {
		padding-top: 50px;
		min-height: 500px;

		.sub-title {
			font-size: 16px;
		}

		&:after {
			font-size: 130px;
			bottom: 20px;
		}
	}

	@include respond('phone') {
		padding-top: 80px;
		min-height: 550px;

		.sub-title {
			font-size: 16px;
		}

		&:after {
			display: none;
		}
	}
}

.custom-react-select {
	width: 320px;
	display: inline-block;
	text-align: center;

	&>div {
		&:nth-child(4) {
			&>div {
				&>div {
					font-size: 16px;
					font-weight: 400;
					text-align: left;
					padding: 2px 16px;
					background-color: #fff;
					color: #000;

					&:hover {
						background-color: var(--primary);
						color: white;
					}
				}
			}
		}

		&:nth-child(3) {
			border-radius: 0;
			//height: 43px;
			color: #fff !important;
			border: none !important;
			outline: none !important;
			background-color: transparent;
			padding: 0;
			line-height: 1.4;
			padding-bottom: 0px;
			border-bottom: 2px solid #000 !important;

			&>div {
				&:first-child {
					&>div {
						color: var(--primary);
						font-weight: 400;
						font-size: 24px;
					}
				}

				&:last-child {
					display: none;
				}
			}
		}

	}

	@include respond('phone') {
		width: 160px;

		&>div {
			&:nth-child(3) {
				border-bottom: 1px solid #000 !important;
				min-height: 30px;

				&>div {
					&:first-child {
						&>div {
							font-size: 15px;
						}
					}
				}
			}
		}
	}

}
// Widget
.widget {
	margin-bottom: 50px;

	@include respond('phone-land') {
		margin-bottom: 35px;
	}
}

.widget-title {
	margin-bottom: 30px;
	padding-bottom: 15px;
	position: relative;

	@include custommq($max: 1199px) {
		margin-bottom: 20px;
	}

	&:after,
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		height: 5px;
		transform: skewX(-20deg);
		background: var(--primary);

		@include respond('laptop') {
			height: 4px;
		}
	}

	&:after {
		left: 0;
		width: 55px;
	}

	&:before {
		left: 60px;
		width: 7px;
	}

	.title {
		position: relative;
		margin-bottom: 0px;
		font-size: 1.5rem;
	}
}


// search-bx
.search-bx {
	form {
		margin-bottom: 10px;
	}

	.form-control {
		border-radius: 0;
		background-color: transparent;
		border: 0;
		z-index: 0 !important;
		padding: 6px 50px 6px 20px;
	}

	.input-group-btn {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		display: flex;
		align-items: center;
	}

	.input-skew {
		background: var(--rgba-primary-1);
	}

	button {
		height: 100%;
		width: 60px;
		font-size: 24px;
		padding: 0;
		justify-content: center;
		border-radius: 0;
		transform: skewX(-12deg);

		i {
			transform: skewX(12deg);
			font-size: 16px;
		}
	}
}


// download-file
.download-file {
	background-image: var(--gradient);
	color: $white;
	padding: 25px 25px 20px 20px;
	border-radius: 4px;

	.title {
		color: $white;
		margin-top: 0;
		margin-bottom: 20px;
		text-align: center;
		font-size: 20px;
		font-weight: 600;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			a {
				background-color: $white;
				color: $black;
				display: block;
				margin-bottom: 10px;
				border-radius: 4px;
				width: 100%;
				padding: 15px;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 600;
				text-align: left;
				align-items: center;
				display: flex;
				justify-content: space-between;

				i {
					width: 18px;
					height: 18px;
					position: relative;

					&:after,
					&:before {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						background-size: contain;
						background-repeat: no-repeat;
						left: 0;
						top: 0;
					}

					&:before {
						-webkit-animation: download1 2s ease infinite;
						animation: download1 2s ease infinite;
					}
				}

				.text {
					display: inline;
					position: relative;
				}

				&:hover {
					.text {
						color: var(--primary);
					}
				}
			}
		}
	}
}

@-webkit-keyframes download1 {

	0%,
	10%,
	20%,
	50%,
	80%,
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	40% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}

	60% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}

@keyframes download1 {

	0%,
	10%,
	20%,
	50%,
	80%,
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	40% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}

	60% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}

// Contact Box
.widget_contact {
	position: relative;
	padding: 50px 45px 45px;
	background-size: cover;
	text-align: center;
	position: relative;
	z-index: 1;
	background-color: var(--primary);
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		width: 45%;
		height: 65%;
		bottom: 0;
		right: 0;
		z-index: -1;
		background-image: url(../images/background/bg-pricetable-2.png);
		background-size: cover;
		background-repeat: no-repeat;
	}

	.icon-bx i {
		font-size: 100px;
		color: var(--theme-text-color);
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 100px;
		margin-bottom: 15px;
	}

	img {
		margin-bottom: 20px;
	}

	h4 {
		font-size: 20px;
		font-weight: 600;
		color: var(--theme-text-color);
		padding-bottom: 20px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		margin-bottom: 10px;
	}

	.phone-number {
		position: relative;
		font-size: 28px;
		color: var(--theme-text-color);
		font-weight: 600;
		margin-bottom: 5px;

		a {
			color: var(--theme-text-color);
		}
	}

	.email {
		position: relative;
		color: var(--theme-text-color);
		font-weight: 600;
		margin-bottom: 30px;

		a {
			color: var(--theme-text-color);
		}
	}
}

.service-carouse {
	.item {
		overflow: hidden;
		border-radius: 6px;
	}
}

.list-2 {
	display: table;

	li {
		width: 50% !important;
		float: left;

		@include custommq ($max: 1200px) {
			width: 100% !important;
		}

		@include respond ('tab-port') {
			width: 50% !important;
		}

		@include respond ('phone') {
			width: 100% !important;
		}
	}
}

// WP Widget Start
/* widget listing*/
.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;

	li {
		padding-bottom: 1.125rem;
		margin-bottom: 0.8125rem;
		position: relative;
		padding: 0.5rem 0rem 0.5rem 0.9375rem;
		margin-bottom: 0;
		line-height: 1.25rem;

		a {
			color: inherit;
			text-transform: capitalize;
			@include transitionMedium;

			&:before {
				content: "\f0da";
				font-family: "Font Awesome 5 Free";
				background: transparent;
				width: auto;
				height: auto;
				position: absolute;
				left: 0;
				top: 8px;
				font-size: 14px;
				font-weight: 700;
				color: var(--primary);
			}

			&:hover {
				color: var(--primary);
			}
		}

		li {
			border-bottom: none;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			line-height: 1.375rem;

			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
			}

			&:before {
				top: 0.5rem;
				left: 0;
			}
		}
	}

	ul {
		padding-left: 1.125rem;
		margin-top: 0.5rem;
		margin-left: -0.9375rem;
	}
}

/*widget categories*/
.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
	margin-bottom: -0.625rem;
}

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
	text-align: right;
	display: table;
	width: 100%;
	padding: 0.5rem 0rem 0.5rem 1.2rem !important;
	line-height: 1.3 !important;
}

.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before {
	content: none !important;
}

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
	float: left;
	text-transform: capitalize;
	text-align: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
	//margin-bottom: 2.5rem;
	padding: 0 !important;
}

// WIDGET RECENT COMMENT
.widget_recent_comments {

	ul {
		margin-top: -0.625rem;

		li {
			padding-left: 1.875rem;
			line-height: 1.625rem;

			a {
				&:hover {
					color: inherit;
				}

				&:before {
					content: none;
				}
			}

			&:before {
				content: "\f27a";
				font-family: "Font Awesome 5 Free";
				background: transparent;
				width: auto;
				height: auto;
				position: absolute;
				left: 0;
				top: 0.625rem;
				font-size: 1.125rem;
				font-weight: 500;
			}
		}
	}
}

// WIDGET META
.widget_meta ul li a abbr[title] {
	color: #333;
	border-bottom: none;
}

// Widget Calender


.widget_calendar {

	th,
	td {
		text-align: center;
		border: 0.0625rem solid var(--rgba-primary-1);
		padding: 0.4em;
	}

	th,
	tfoot td {
		border-color: rgba(0, 0, 0, 0.05);

		&:last-child {
			border: 0;
		}
	}

	tr {
		border-bottom: 0.0625rem solid var(--rgba-primary-1);

		&:nth-child(2n+2) {
			background-color: var(--rgba-primary-05);
		}
	}

	table {
		border-collapse: collapse;
		margin: 0;
		width: 100%;
	}

	.calendar_wrap th,
	tfoot td a {
		color: #fff;
		background: var(--primary);
	}

	.calendar_wrap {
		td {
			a {
				font-weight: 600;
				text-transform: uppercase;
				color: inherit;
			}
		}
	}

	caption {
		text-align: center;
		font-weight: 600;
		caption-side: top;
		background: var(--primary);
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		color: #fff;
		text-transform: uppercase;
		font-size: 0.9375rem;
	}
}

.wp-block-calendar {
	table {
		th {
			font-weight: 400;
			background: var(--primary);
			color: #fff;
		}

		caption {
			color: #fff;
		}
	}
}

footer {
	.widget_calendar {

		th,
		td {
			border-color: rgba(255, 255, 255, 0.1);
		}

		tr {
			border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);

			&:nth-child(2n+2) {
				background-color: rgba(255, 255, 255, 0.05);
			}
		}
	}
}

.wp-calendar-nav {
	background: var(--primary);
	color: #fff;
	display: flex;
	justify-content: space-between;
	text-align: center;

	span {
		padding: 0.3125rem 1.25rem;
		width: 44%;
		border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);

		a {
			color: #fff;
		}
	}
}




// Widget Archive 
.widget_archive select {
	width: 100%;
	padding: 0.3125rem;
	border: 0.0625rem solid #CCC;
}

select {
	@extend .form-select;
}

.list-2-column {
	ul {
		display: table;

		li {
			width: 50%;
			float: left;
		}
	}
}

// Widget Archive 
.widget_categories,
.widget_archive {
	select {
		width: 100%;

		border: 0.0625rem solid #CCC;
	}
}

// WIDGET TEXT 
.widget_text {
	select {
		width: 100%;
		border: 0.0625rem solid #CCC;

		option {
			width: 100%;
		}
	}

	p {
		font-size: 0.9375rem;
		line-height: 1.75rem;
	}

}

/*widget rss  */
.rsswidget img {
	display: inherit;
}

.widget-title .rsswidget {
	display: inline-block;
}

ul a.rsswidget,
ul cite {
	font-weight: 600;
	color: var(--title);
}

ul {
	a.rsswidget {
		font-size: 1.125rem;
		@include transitionMedium;

		&:hover {
			color: var(--primary);
		}
	}
}

.rssSummary {
	margin: 1.25rem 0;
	line-height: 1.7;
	font-size: 15px;
}

.rss-date {
	display: block;
	margin-top: 0.3125rem;
	font-weight: 400;
}

.widget_rss ul li {
	margin-bottom: 1.25rem;
	padding-bottom: 1.25rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 0;

	&:last-child {
		margin-bottom: 0;
		border-bottom: 0;
		padding-bottom: 0;
	}
}


// WIDGET GALLERY
.widget_gallery {
	ul {
		padding-left: 0;
		display: flex;
		margin-left: -0.3rem;
		margin-right: -0.3rem;
	}

	&.gallery-grid-2 li {
		width: 50%;
	}

	&.gallery-grid-4 li {
		width: 25%;
	}

	li {
		display: inline-block;
		width: 33.33%;
		float: left;
		@include transitionMedium;
		padding: 5px;

		img {
			display: inline-block;
			width: 100%;
		}

		&:nth-child(3n-3) {
			margin-right: 0;
		}

		&:hover {
			opacity: 0.7;
		}

		a {
			display: inline-block;
		}
	}
}


// widget_tag_cloud
.widget_tag_cloud {
	.tagcloud {
		clear: both;
		display: table;

		a {
			position: relative;
			padding: 8px 15px;
			display: inline-block;
			margin: 0 10px 10px 0;
			font-size: 14px;
			color: var(--primary);
			line-height: 1.4;
			border: 0;
			background: var(--rgba-primary-1);
			border-radius: 0;
			transform: skewX(-12deg);

			@include respond('laptop') {
				padding: 6px 18px;
				margin: 0 10px 10px 0;
			}

			@include custommq($max: 1199px) {
				padding: 6px 14px;
				margin: 0 8px 8px 0;
			}

			&:hover {
				box-shadow: 0px 5px 12px var(--rgba-primary-4);
				background-color: var(--primary-hover);
				border-color: var(--primary-hover);
				color: #fff;
			}

			span {
				display: inline-block;
				transform: skewX(12deg);
			}
		}
	}
}

// Recent Posts Entry
.recent-posts-entry {
	.widget-post-bx {
		.widget-post {
			display: flex;
			align-items: center;
			border-radius: $border-radius;
			margin-bottom: 20px;
			transition: all 0.5s;

			.dz-media {
				width: 92px;
				min-width: 92px;
				margin-right: 15px;

				@include custommq($max: 1199px) {
					width: 75px;
					min-width: 75px;
				}
			}

			.dz-meta {
				margin-bottom: 0;

				ul {
					line-height: 1;

					li {
						font-size: 15px;
					}
				}
			}

			.title {
				line-height: 1.3;
				font-family: 'Poppins';
				margin-bottom: 8px;
				display: block;
				font-weight: 600;
				font-size: 16px;
			}

			.post-date {
				font-size: 14px;
			}

			&:not(:last-child) {
				margin-bottom: 20px;
				padding-bottom: 15px;
				border-bottom: 1px solid lightgrey;
				border-image-slice: 1;
				border-image-source: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
			}

		}
	}
}

// widget_services
.service_menu_nav {
	background: #222222;
	position: relative;
	padding: 40px 45px;
	z-index: 1;
	box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);

	@include respond('phone-land') {
		padding: 40px;
	}

	&:after,
	&:before {
		content: '';
		position: absolute;
		height: 50%;
		z-index: -1;
		bottom: 0;
		right: 0;
		@include transitionMedium;
		background-size: cover;
		background-repeat: no-repeat;
	}

	&:after {
		background-image: url(../images/background/bg-pricetable-1.png);
		width: 10%;
		height: 20%;
	}

	&:before {
		background-image: url(../images/background/bg-pricetable-2.png);
		width: 40%;
		opacity: 0.2;
	}

	ul {
		li {
			padding: 0;

			a {
				display: block;
				position: relative;
				padding: 15px 0;
				margin-bottom: 10px;
				color: #fff;
				font-size: 18px;
				z-index: 1;
				font-weight: 500;
				@include transitionMedium;

				@include respond('phone-land') {
					padding: 12px 0;
					margin-bottom: 5px;
					font-size: 16px;
				}

				&:before,
				&:after {
					content: '';
					position: absolute;
					top: 0;
					height: 100%;
					z-index: -1;
					background-color: var(--primary);
					opacity: 0;
					transform: translateX(15px) skewX(-10deg);
					@include transitionMedium;
				}

				&:after {
					width: 100%;
					width: calc(100% + 70px);
					right: 0px;

					@include respond('phone-land') {
						width: calc(100% + 50px);
					}
				}

				&:before {
					width: 7px;
					right: -15px;
				}
			}

			&:hover,
			&.current-menu-item,
			&.active {
				a {
					color: var(--theme-text-color);

					&:after,
					&:before {
						transform: translateX(0px) skewX(-10deg);
						opacity: 1;
					}
				}
			}
		}
	}

	svg {
		position: absolute;
		bottom: -23px;
		left: -20px;
		transform: rotateY(180deg);
		height: 50px;
		width: 175px;
	}
}

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
	margin-bottom: 20px;

	select {
		@extend .form-control;

		&:after {
			content: "\f078";
			font-weight: 900;
			font-family: 'Line Awesome Free';
			position: absolute;
			right: 50px;
			left: auto;
			top: 50%;
		}
	}
}

.post-tags {
	strong {
		font-weight: 700;
		color: #212529;
		margin-right: 10px;
	}

	a {
		margin-right: 10px;
		display: inline-block;
	}
}


//widget_profile
.widget_profile {
	.dz-media {
		width: 100%;
		min-width: 100%;

		img {
			border-radius: var(--border-radius-base);
		}
	}

	p {
		font-size: 16px;
	}
}

// Widget Newslatter
.widget-newslatter {
	.form-control {
		background-color: #f3f4f6;
		border-radius: var(--border-radius);
		border: 0;
	}

	p {
		font-size: 16px;
	}

	button {
		width: 60px;
		height: 60px;
		padding: 0;
		justify-content: center;
	}

	.input-group {
		margin-bottom: 0;
	}
}

// Widget Style 1
.shop-widget,
.widget.style-1 {
	box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1);
	padding: 35px;
	border-bottom: 4px solid;
	border-color: var(--primary);

	// Widget Search
	.search-bx {
		margin-bottom: 0;

		.form-control {
			background-color: #f3f4f6;
			box-shadow: unset;
		}

		.input-group {
			margin-bottom: 0;
		}
	}

	@include respond('tab-land') {
		padding: 25px;
	}
}

.widget_working {
	&.style-2 {
		padding: 30px;
		padding-bottom: 15px;
		background: var(--secondary);

		ul {
			width: 100%;
			display: flex;
			flex-wrap: wrap;

			li {
				width: 50%;
				color: #fff;
				display: inline-block;
				vertical-align: top;
			}
		}
	}

}

// Widget
.side-bar h1 {
	font-size: 2rem;
}

.side-bar h2 {
	font-size: 1.625rem;
}

.side-bar h3 {
	font-size: 1.5rem;
}

.side-bar h4 {
	font-size: 1.25rem;
}

.side-bar h5 {
	font-size: 1.125rem;
}

.side-bar h6 {
	font-size: 1rem;
}

.sidebar-widget,
.shop-widget {

	.wp-block-woocommerce-attribute-filter,
	.wp-block-group__inner-container {

		&>h1,
		&>h2,
		&>h3,
		&>h4,
		&>h5,
		&>h6 {
			position: relative;
			border-bottom: 2px solid rgba(0, 0, 0, 0.1);
			padding-bottom: 10px;
			margin-bottom: 30px;
		}
	}
}

.comments-title,
.blog-title,
.comment-reply-title,
.wp-block-search .wp-block-search__label {
	color: var(--title);
	border-bottom: 2px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 10px;
	margin-bottom: 30px;
	@extend h4;
}

.wp-block-group:where(.has-background) {
	padding: 1.25em 2.375em;
}

// Widget Locations
.widget_working {
	ul {
		li {
			display: block;
			margin-bottom: 15px;

			.time {
				font-weight: 600;
				color: var(--primary);
				display: block;
			}
		}
	}
}

.widget_getintuch ul {
	li {
		position: relative;
		margin-bottom: 30px;
		padding-left: 45px;
		color: #fff;

		@include respond('tab-land') {
			padding-left: 35px;
		}

		@include custommq ($max: 1199px) {
			margin-bottom: 25px;
			padding-left: 35px;
		}

		h5 {
			font-size: 20px;
			line-height: 1.33;
			margin-bottom: 5px;
		}

		i {
			position: absolute;
			font-size: 24px;
			line-height: 1;
			left: 0;
			top: 2px;
			color: var(--primary);

			@include respond('phone') {
				font-size: 20px;
			}
		}

		p {


			@include respond('tab-port') {
				font-size: 14px;
			}

			@include respond('phone') {
				font-size: 12px;
			}
		}
	}
}
[data-theme-color="color_1"] {
  /* Primary */
  // --primary: #ff8139;
  --primary: #FF3700;
  --primary-hover: #ff6106;
  --primary-dark: #f03501;

  /* Primary RGBA */
  --rgba-primary-1: rgba(255, 129, 57, 0.1);
  --rgba-primary-2: rgba(255, 129, 57, 0.2);
  --rgba-primary-3: rgba(255, 129, 57, 0.3);
  --rgba-primary-4: rgba(255, 129, 57, 0.4);
  --rgba-primary-5: rgba(255, 129, 57, 0.5);
  --rgba-primary-05: rgba(255, 129, 57, 0.05);
  --rgba-primary-6: rgba(255, 129, 57, 0.6);
  --rgba-primary-7: rgba(255, 129, 57, 0.7);
  --rgba-primary-8: rgba(255, 129, 57, 0.8);
  --rgba-primary-9: rgba(255, 129, 57, 0.9);

  /* Other */
  --secondary: #232323;
  --theme-text-color: #fff;
  --title: #222222;

  /* Gradient */
  --gradient: linear-gradient(89deg, #ff3c11 1.12%, #ff8139 44%);
  --gradient-1: linear-gradient(90deg, #ff3f13 47.55%, #ff7f38 100.15%);
  --gradient-2: linear-gradient(90deg, #ff8039 0%, #ff3f13 100%);
  --gradient-3: linear-gradient(180deg, var(--primary) 0%, #ff0000 100%);

  /* Box Shadow */
  --primary-shadow: linear-gradient(90deg, #fcde3b 47.55%, #ebc600 100.15%);

  /* extra variable */
  /* --theme2-bg-pattern1: url(../images/pattern/theme/bg-pattern1.svg); */

  --theme3-bg-color: #201916;
  --theme3-secondary: #301f18;
  --theme3-secondary2: #ffdd39;
}
.margin-head {


    @media only screen and (max-width: 374px) {
        margin-top: 50px !important;
    }

    @media only screen and (min-width: 375px) and (max-width: 575px) {
        /* Your styles here */
        margin-top: 60px !important;
    }

    @media only screen and (min-width: 576px) and (max-width: 768px) {
        margin-top: 100px !important;
    }

    @media only screen and (min-width: 769px) and (max-width: 1024px) {
        margin-top: 105px !important;
    }

    @media only screen and (min-width: 1025px) {
        margin-top: 140px !important;
    }

    // @include respond('phone') {
    //     margin-top: 260px !important;
    // }
}

// .margin-title {
//     padding-top: 50px;
// }

.custom-modal .modal-content {
    border-radius: 0;
}
.styleswitcher{
	left: -450px;
    padding: 15px;
    position: fixed;
    top: 50px;
    width: 450px;
    z-index: 99999;
    direction: ltr;
    height: calc(100% - 100px);
}
.styleswitcher .overlay-switch{
	border-radius: 10px;
	position: absolute;
	width: 100%;
	height: 100%;
	background: #000;
	left: 0;
	top: 0;
	opacity: 0.04;
	-webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}
.styleswitcher.open .overlay-switch{
	position: fixed;
}
.styleswitcher .styleswitcher-inner{
	background-color: #ffffff;
	height: 100%;
	left: 0;
	overflow: auto;
	position: absolute;
	top: 0;
	width: 100%;
	padding: 30px;
	border-radius: 10px;
}
.styleswitcher .styleswitcher-inner::-webkit-scrollbar{
	width: 5px;
}
.styleswitcher .styleswitcher-inner::-webkit-scrollbar-thumb{
	background: #a0a0a0; 
}
.styleswitcher.open .styleswitcher-inner{
	box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.20);
}
.styleswitcher .switcher-btn-bx{
	box-shadow: 7px 0 5px rgba(0, 0, 0, 0.1);
	position: absolute;
	right: -44px;
	top: 200px;
	z-index: 1;
}
.styleswitcher .sw-main-title{
	font-size: 20px;
	font-weight: 500;
	padding: 20px 20px;
	color: #000;
	display: flex;
	border-bottom: 1px solid #eee;
	margin-left: -30px;
	margin-right: -30px;
	margin-top: -30px;
	margin-bottom: 30px;
	background: #f9f9fd;
	align-items: center;
	justify-content: space-between;
}
.styleswitcher .switcher-title{
	font-weight: 600;
	margin: 0 0 10px 0;
	font-size: 12px;
	text-transform: uppercase;
	color: #000;
	letter-spacing: 1px;
}
.styleswitcher .site-button{
	margin-top: 20px;
	margin-right: 20px;
	margin-left: 20px;
}
.styleswitcher h6.switcher-title{
	color: #717171;
}
.styleswitcher .btn.btn-sm{
	font-size: 12px;
	padding: 8px 15px;
}
.theme-box{
	margin-bottom:30px;
}
.theme-design{
    display: flex;
    margin-left: -7px;
    margin-right: -7px;
    padding: 12px 10px;
    border: 1px solid #eee;
    border-radius: 6px;
    margin-bottom: 20px;
    background: #f9f9fd;
}
.theme-design .theme-box{
	padding-left: 7px;
	padding-right: 7px;
	margin-bottom:0;
}
.color-skins li a:before,
.bg-color-switcher li a:before{
	content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: 1.5px solid #000;
    border-color: #000;
    border-radius: 6px;
    -webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 0;
}
.color-skins li.active a:before,
.bg-color-switcher li.active a:before{
    transform: scale(1.2);	
	opacity: 1;
}
/* Styleswitcher Right End */
.switch-btn {
    background: var(--primary);
    box-shadow: 2px 1px 60px rgba(0, 0, 0, 0.10);
    color: var(--theme-text-color);
    cursor: pointer;
    display: block;
    font-size: 21px;
    height: 45px;
    line-height: 43px;
    text-align: center;
    width: 45px;
    border-radius: 0 4px 4px 0;
    border-left: 0;
}

.switch-btn span{
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear;
}
.switch-btn:hover,
.switch-btn:active,
.switch-btn:focus{
	color: var(--theme-text-color);
}
.tab-checkbx{
	background: #dfe0ed;
    padding: 4px;
    border-radius: 6px;
    display: flex;
	align-items: center;
}
.tab-checkbx li{
	width:50%;
	flex:0 0 50%;
}
.tab-checkbx li a{
	text-align:center;
	color:#575971;
	display:block;
	text-transform:uppercase;
	font-weight:500;
	letter-spacing:1px;
	font-size: 12px;
	padding: 5px 10px;
	border-radius: 4px;			
}
.tab-checkbx li.active a{
	background: #fff;
	color: #000;	
	box-shadow: 2px 2px 5px 0 rgba(4, 0, 51, 0.20);
}
.layout-view{
	display:flex;
}
.layout-view li{
	padding: 0 5px;
	width: 33.33%;
	flex: 0 0 33.33%;
	text-align: center;
}
.layout-view li a div{
	margin-left: auto;
	margin-right: auto;
	width: 100px;
	height: 75px;
	border: 3px solid;
	color:#7b7c87;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.layout-view li a div span{
	display:block;
	border:2px solid;
	border-radius:3px;
}
.layout-view li a > span{
	color:#575971;
	font-size:12px;
	font-weight: 500;
}
.layout-view li.frame a div{
	padding: 5px;
}
.layout-view li.frame a div span{
	width:100%;
	height:100%;	
}
.layout-view li.boxed a div span{
	width: 80%;
	height: 100%;
	border-width: 0px 2px 0 2px;
	border-radius: 0;
}
.layout-view li.active{	
	color:var(--primary);
}
.layout-view li.active a div{
	box-shadow:0 10px 20px 0 var(--rgba-primary-1);
	color:var(--primary);
	background: var(--rgba-primary-1);
}
.layout-view li.active a > span{
	color:var(--primary);
}
.bg-color-switcher,
.color-skins{
	display: flex;
	margin: -4px;
}
.bg-color-switcher li,
.color-skins li{
	cursor: pointer;
	display: inline-block;
	list-style: outside none none;
	margin: 4px;
}
.bg-color-switcher li a,
.color-skins li a{
	background-size:100%;
	display: block;
	height: 35px;
	width: 35px;
	height: 36px;
	width: 36px;
	border-radius: 6px;
	position: relative;
}
.bg-color-switcher li.active a:after,
.color-skins li.active a:after{
	content: "\f00c";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 16px;
}
.bg-color-switcher li .bg-color-1,
.bg-color-switcher li .skin-1,
.color-skins li .bg-color-1,
.color-skins li .skin-1{
	background-color: #FF8139;	
}
.color-skins li a.skin-1:before,
.bg-color-switcher li a.bg-color-1:before{
	border-color: #FF8139;
}

.bg-color-switcher li .bg-color-2,
.bg-color-switcher li .skin-2,
.color-skins li .bg-color-2,
.color-skins li .skin-2{
	background-color: #f7244f;
}
.color-skins li a.skin-2:before,
.bg-color-switcher li a.bg-color-2:before{
	border-color: #f7244f;
}

.bg-color-switcher li .bg-color-3,
.bg-color-switcher li .skin-3,
.color-skins li .bg-color-3,
.color-skins li .skin-3{
	background-color: #06C6D1;
}
.color-skins li a.skin-3:before,
.bg-color-switcher li a.bg-color-3:before{
	border-color: #06C6D1;
}

.bg-color-switcher li .bg-color-4,
.bg-color-switcher li .skin-4,
.color-skins li .bg-color-4,
.color-skins li .skin-4{
	background-color: #b8c724;
}
.color-skins li a.skin-4:before,
.bg-color-switcher li a.bg-color-4:before{
	border-color: #b8c724;
}

.bg-color-switcher li .bg-color-5,
.bg-color-switcher li .skin-5,
.color-skins li .bg-color-5,
.color-skins li .skin-5{
	background-color: #ffcc00;
}
.color-skins li a.skin-5:before,
.bg-color-switcher li a.bg-color-5:before{
	border-color: #ffcc00;
}

.bg-color-switcher li .bg-color-6,
.bg-color-switcher li .skin-6,
.color-skins li .bg-color-6,
.color-skins li .skin-6{
	background-color: #b65fbd;
}
.color-skins li a.skin-6:before,
.bg-color-switcher li a.bg-color-6:before{
	border-color: #b65fbd;
}

.bg-color-switcher li .bg-color-7,
.bg-color-switcher li .skin-7,
.color-skins li .bg-color-7,
.color-skins li .skin-7{
	background-color: #977fff;
}
.color-skins li a.skin-7:before,
.bg-color-switcher li a.bg-color-7:before{
	border-color: #977fff;
}

.bg-color-switcher li .bg-color-8,
.bg-color-switcher li .skin-8,
.color-skins li .bg-color-8,
.color-skins li .skin-8{
	background-color: #49bb98;
}
.color-skins li a.skin-8:before,
.bg-color-switcher li a.bg-color-8:before{
	border-color: #49bb98;
}

.bg-color-switcher li .bg-color-9,
.bg-color-switcher li .skin-9,
.color-skins li .bg-color-9,
.color-skins li .skin-9{
	background-color: #3f8cff;
}
.color-skins li a.skin-9:before,
.bg-color-switcher li a.bg-color-9:before{
	border-color: #3f8cff;
}

.background-switcher{
	display: flex;
	margin: -4px;	
}
.background-switcher li{
	cursor: pointer;
	display: inline-block;
	height: 36px;
	margin: 4px;
	width: 36px;
	position: relative;
	overflow: hidden;
	border-radius: 6px;
}
.background-switcher li.active{
	box-shadow: 0 0 0px 3px #fff ,0 0 0px 6px var(--rgba-primary-5);
}
.background-switcher li.active:after{
	content: "\f00c";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 16px;
	top: 0;
}
.pattern-switcher{
	display: flex;
	margin: -4px;
}
.pattern-switcher li{
	cursor: pointer;
	display: inline-block;
	height: 36px;
	margin: 4px;
	width: 36px;
	position: relative;
	overflow: hidden;
	border-radius: 6px;
	border: 1px solid #b3b3b3;
}
.pattern-switcher li.active{
	box-shadow: 0 0 0px 3px #fff ,0 0 0px 6px var(--rgba-primary-5);	
}
.pattern-switcher li.active:after{
	content: "\f00c";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--primary);
	font-size: 16px;
	top: 0;
}

/* background color switcher */

/* Range */
.theme-box .range-slider{
	margin-top:15px;
}
.theme-box .price .amount{
	font-weight: 500;
	border: 0;
	font-size: 14px;
	margin-bottom: 12px;
	background-color: transparent;
}
#ThemeRangeSlider .ui-widget-content{
    background: var(--rgba-primary-3);
}
.ui-state-default,
.ui-widget-content .ui-state-default, 
.ui-widget-header .ui-state-default{
    border: 2px solid var(--primary)!important;
}
.theme-view-wrapper .theme-box:not(:last-child){
	margin-bottom: 15px;
}

@media only screen and (max-width: 1280px){
	.theme-design.theme-layout-wrapper,
	.theme-design.theme-background-wrapper{
		display: none;
	}
	.styleswitcher{
		left: -300px!important;
		width: 300px;
		-webkit-transition: all 0.5s;
		-ms-transition: all 0.5s;
		transition: all 0.5s;
	}
	.styleswitcher.open{
		left: 30px!important;
	}
	.styleswitcher .sw-main-title{
		display: block;
		margin: -30px -30px 20px;
	}
	.styleswitcher .sw-main-title .btn{
		display: block;
		width: 100%;
		margin-top: 5px;		
	}
	.styleswitcher .color-skins{
		justify-content: start;
		flex-wrap: wrap;
	}
}
@media only screen and (max-width: 591px){
	.styleswitcher{
		width: 250px;
		left: -250px !important;
		top: 0;
		height: 100%;
	}
	.styleswitcher.open{
		left: 0 !important;
	}
	.styleswitcher .styleswitcher-inner {
		border-radius: 0;
		padding: 25px;
	}
	.styleswitcher .sw-main-title {
		margin: -30px -25px 20px;
	}
	.styleswitcher .theme-design {
		padding: 10px 5px;
	}
	.styleswitcher .color-skins li{
		margin: 4px 5px 2px 0px;
	}
	.styleswitcher .color-skins li a{
		height: 30px;
		width: 30px;		
	}
	.styleswitcher .color-skins li.active a::after {
		font-size: 12px;
	}
	.switcher-btn-bx{
		right: -35px!important;
	}
	.switcher-btn-bx .switch-btn{
		font-size: 16px;
		height: 35px;
		line-height: 35px;
		width: 35px;
	}
}